type Lang = { key: string };

export const findById = (
    values: Record<string, any>,
    ids: number[],
    newValues: Record<string, any>,
    langs: Lang[]
): Record<string, any> => {
    let updatedValues = { ...values };
    let videoValues: any[] = [];

    langs.forEach((lang) => {
        Object.entries(newValues).forEach(([key, value]) => {
            if (key.includes("video") && key.includes(lang.key)) {
                videoValues.push({
                    file: value,
                    lang_key: lang.key,
                    id: key.replace(/\D/g, "")
                });
            }
        });
    });


    const updateNestedValues = (value: any, id: number): any => {
        if (!value) return value;

        if (value.id === id) {
            langs.forEach((lang) => {
                let valueUpdated = false;

                Object.entries(newValues).forEach(([newKey, newValue]) => {
                    if (newKey.includes(String(id)) && newKey.includes(lang.key)) {
                        valueUpdated = true;
                        const isMedia = value.type === "image" || value.type === "video";
                        const isLink = value.type === "link";
                        const existingValues = value.values || [];
                        const exists = existingValues.some(
                            (item: any) =>
                                item.lang_key === lang.key &&
                                (item.file === newValue || item.value === newValue || item.file === "")
                        );

                        if (!exists) {
                            const updatedItem = isMedia
                                ? { file:  value.type === "image" ? newValue : "", lang_key: lang.key }
                                : { value: newValue, lang_key: lang.key };

                            value.values = [...existingValues, updatedItem];
                        }

                        if (isLink) {
                            for (let key in newValues) {
                                if (key?.includes("link") && key?.includes(value.id)) {
                                    value.link = newValues[key];
                                }
                            }
                        }
                    }
                });

                if (!valueUpdated) {
                    const isMedia = value.type === "image" || value.type === "video";
                    const updatedItem = isMedia
                        ? { file: "", lang_key: lang.key }
                        : { value: "", lang_key: lang.key };

                    value.values = [...(value.values || []), updatedItem];
                }
            });
            return value;
        }

        if (Array.isArray(value.values)) {
            value.values = value.values.map((nestedValue: any) =>
                updateNestedValues(nestedValue, id)
            );
        }

        if (typeof value === "object") {
            Object.entries(value).forEach(([key, nestedValue]) => {
                value[key] = updateNestedValues(nestedValue, id);
            });
        }

        return value;
    };

    ids.forEach((id) => {
        Object.entries(updatedValues).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                updatedValues[key] = value.map((item: any) =>
                    updateNestedValues(item, id)
                );
            } else if (typeof value === "object" && value !== null) {
                updatedValues[key] = updateNestedValues(value, id);
            }
        });
    });

    console.log(videoValues);

    return { result: updatedValues, videoValues: videoValues.length !== 0 ? videoValues: null };
};


export const extractIds = (input: Record<string, any>): number[] => {
    const ids = new Set<number>();

    Object.keys(input).forEach((key) => {
        const match = key.match(/(\d+)-\w{2}$/);
        if (match) {
            const id = parseInt(match[1], 10);
            ids.add(id);
        }
    });

    return Array.from(ids);
};
