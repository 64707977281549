import React, { useEffect, useState } from 'react';
import s from './DropDown.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import imageCompression from "browser-image-compression";

import { Field, FormikErrors, FormikValues } from "formik";
import 'react-quill/dist/quill.snow.css';
import translateIcon from "assets/icons/translate.webp";
import {translateText} from "utils/translateText";
import {useStatusContext} from "../StatusProvider";
import TinyEditor from 'components/TinyEditor/TinyEditor';

type DropDownProps = {
    langs: { key: string }[];
    errors: any;
    touched: any;
    kind: string;
    label: string;
    module?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
    values?: FormikValues;
};

export const DropDown = ({ langs, errors, touched, kind, label, module, setFieldValue, values }: DropDownProps) => {
    const [hideStates, setHideStates] = useState<Record<string, boolean>>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs[0]?.key || '');
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const [translateValues, setTranslateValues] = useState<any>();
    const { openStatus } = useStatusContext();
    const [isLoadingTranslate, setIsLoadingTranslate] = useState<boolean>(false);
    const MAX_TRANSLATE_LENGTH = 5000;

    function getCurrentValue(){
        let value
        for(let i=0; i<langs?.length; i++){
            if(translateValues[langs?.[i].key]?.length){
                value = translateValues[langs?.[i].key]
                break
            }
        }
        return value
    }

    const handleTranslate = async () => {
        try {
            setIsLoadingTranslate(true);
            const text = getCurrentValue();

            if (!text) {
                setIsLoadingTranslate(false);
                openStatus('error', "status.notPicture");
                return;
            }

            const chunks = [];
            let chunkStart = 0;

            while (chunkStart < text.length) {
                let chunkEnd = chunkStart + MAX_TRANSLATE_LENGTH;
                if (chunkEnd < text.length) {
                    const lastSpaceIndex = text.lastIndexOf(' ', chunkEnd);
                    if (lastSpaceIndex > chunkStart) {
                        chunkEnd = lastSpaceIndex;
                    }
                }
                chunks.push(text.slice(chunkStart, chunkEnd));
                chunkStart = chunkEnd + 1;
            }

            const languages = langs?.map((l: any) => l.key) || [];
            const results = await Promise.all(chunks.map(chunk => translateText(chunk, languages)));

            const mergedResult = results.reduce((acc, part) => {
                for (const key in part) {
                    acc[key] = (acc[key] || '') + part[key];
                }
                return acc;
            }, {} as Record<string, string>);

            setTranslateValues(mergedResult);
            setIsLoadingTranslate(false);
        } catch (error) {
            setIsLoadingTranslate(false);
            openStatus('error', "status.translationError");
            console.error("Error during translation:", error);
        }
    };

    useEffect(() => {
        setHideStates(langs?.reduce((acc, lang) => ({ ...acc, [lang.key]: true }), {}));
    }, [langs]);

    const toggleHide = (key: string) => {
        setHideStates(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleLanguageChange = (langKey: string) => {
        setSelectedLang(langKey);
    };

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        key: string
    ) => {
        const file = event.target.files?.[0];

        if (file && setFieldValue) {
            try {
                const fileType = file.type.split("/")[0];

                if (fileType === "image") {
                    const options = {
                        maxSizeMB: 10,
                        maxWidthOrHeight: 1024,
                        useWebWorker: true,
                    };

                    const compressedFile = await imageCompression(file, options);

                    const reader = new FileReader();
                    reader.onloadend = () => setFieldValue(`${kind}-${key}`, reader.result as string);
                    reader.readAsDataURL(compressedFile);
                } else {
                    setFieldValue(`${kind}-${key}`, file);
                }
            } catch (error) {
                console.error("Помилка обробки файлу:", error);
            }
        }
    };

    const renderMediaContent = (fieldName: string, value: any, type: "image" | "video") => {
        const  src = !(value instanceof File) && (value && (value?.startsWith("https") || value?.startsWith("data"))) ? value : `${API_ADDRESS}${value}`;

        return (
            <div className={s.image_wrapper}>
                {(type === "image") ?
                    <img src={src} alt="Upload" className={s.image} /> :
                    <video
                        src={value instanceof File ? URL.createObjectURL(value) : src}
                        className={s.image}
                        controls
                    />
                }
                <Field
                    className={s.change_input}
                    name={`${fieldName}-${type}`}
                    type="file"
                    accept={`${type}/*`}
                    onChange={(e: any) => handleFileUpload(e, fieldName.split('-')[1])}
                />
            </div>
        );
    };

    const renderContent = (v: { key: string }) => {
        const fieldName = `${kind}-${v.key}`;
        const value = values?.[fieldName];

        if (label === "image" || label === "video") {
            return renderMediaContent(fieldName, value, label);
        }

        return <Field
            name={fieldName}
            component={TinyEditor}
            translateValues={translateValues}
            setTranslateValues={setTranslateValues}
        />;
    };

    const renderErrors = (key: string) => {
        const errorKey = `${kind}-${key}`;
        return touched?.[errorKey] && errors?.[errorKey] && (
            <span className={s.error_text}>{errors[errorKey]}</span>
        );
    };

    return (
        <div className={s.dropdown}>
            <div className={s.languageButtons}>
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) && langs?.map(v => (
                    <div
                        key={v.key}
                        onClick={() => handleLanguageChange(v.key)}
                        className={`${s.languageButton} ${selectedLang === v.key ? s.selected : ''}`}
                    >
                        {v.key}
                        {(values?.[`${kind}-${v.key}`] && values?.[`${kind}-${v.key}`] !== "<p><br></p>") &&
                            <CheckIcon className={s.check_icon}/>}
                    </div>
                ))}
                {typeof kind === "string" && (!kind.includes("image") && !kind.includes("video")) &&
                    (isLoadingTranslate ? <LoadingIcon className={s.loadingIcon} /> :
                        <img
                            src={translateIcon}
                            alt={"translateIcon"}
                            className={s.translateIcon}
                            onClick={handleTranslate}
                        />
                    )
                }
            </div>

            {typeof kind === "string" && (kind.includes("image") || kind.includes("video")) ? (
                <div className={s.content_row}>
                    {langs?.map(v => (
                        <div key={v.key} className={s.flex}>
                            {!module && (
                                <div className={s.content_head}>
                                    <p>{label} <span>{v.key}</span></p>
                                </div>
                            )}
                            {renderContent(v)}
                            {renderErrors(v.key)}
                        </div>
                    ))}
                </div>
            ) : (
                langs?.map(v => (
                    <div
                        key={v.key}
                        className={`${s.content} ${!hideStates[v.key] ? s.hide : ''} ${v.key !== selectedLang ? s.none : ''}`}
                    >
                        {!module && (
                            <div className={s.content_head} onClick={() => toggleHide(v.key)}>
                                <p>{label} <span>{v.key}</span></p>
                                <ArrowTools className={s.arrow}/>
                            </div>
                        )}
                        {renderContent(v)}
                        {renderErrors(v.key)}
                    </div>
                ))
            )}
        </div>
    );
};
