import React, {useEffect} from 'react';
import { useState } from 'react';
import s from './Users.module.scss';
import {useDeleteUserMutation, useGetUsersQuery} from "../../redux/apis/userApi";
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import {AddUser} from "components/AddUser/AddUser";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import questionMark from 'assets/images/questionMark.webp';
import {Preloader} from "components/Preloader/Preloader";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {Table} from "components/Table/Table";
import {useStatusContext} from "components/StatusProvider";
import {useTranslation} from "react-i18next";

export const Users = () => {
    const { data: users } = useGetUsersQuery({});
    const [query, setQuery] = useState<string>('');
    const [filteredUsers, setFilteredUsers] = useState<any[]>();
    const [addUser, setAddUser] = useState<boolean>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<number>();
    const [user, setUser] = useState<any>();
    const { t } = useTranslation();
    const [confirmState, setConfirmState] = useState(false);
    const [role, setRole] = useState<string>();
    const headers = ['ID', t("pagesModal.name"), t("users.role"), t("users.email"), ""];
    const { openStatus } = useStatusContext();
    const [delUser, {isLoading: isLoadingDelUser}] = useDeleteUserMutation();

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    if (users?.isFetching || isLoadingDelUser) return <Preloader />

    if (users?.error) {
        if (!confirmState && users?.error?.data.message === 9) {
            openStatus('warning', "status.unfortunately");
        }
    }

    async function deleteUser(){
        const result = await delUser(idDelete);

        if (result.data.message === 7 ) {
            openStatus('success', "status.operationSuccessful");
        }
        setShowModal(false);
    }

    const openDeleteModal = (id: number, role: string) => {
        setIdDelete(id);
        setRole(role);
        setShowModal(true);
    }

    const handleSearch = () => {
        const results = users.filter((user: any) =>
            (user.id && String(user.id).toLowerCase().includes(query.toLowerCase())) ||
            (user.info?.name && String(user.info.name).toLowerCase().includes(query.toLowerCase())) ||
            (user.email && String(user.email).toLowerCase().includes(query.toLowerCase()))
        );

        setFilteredUsers(results);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleEdit = (user: any) => {
        setUser(user);
        setAddUser(true);
    }

    const handleAddUser = () => {
        setUser(null);
        setAddUser(true);
    }

    const users_view = filteredUsers?.map((user: any) => {
        return (
            <tr key={user.id}>
                <td><span>{user.id}</span></td>
                <td>{user.info.name}</td>
                <td>{user.info.role}</td>
                <td>{user.email}</td>
                <td className={s.icons}>
                    <span
                        className={s.tools_icon}
                        onClick={(ev) => openDeleteModal(user.id, user.info.name)}
                    >
                        <Delete />
                    </span>
                    <span className={s.tools_icon} onClick={() => handleEdit(user)}>
                        <Edit />
                    </span>
                </td>
            </tr>
        )
    })

    if (addUser) {
        return (
            <section className={s.users}>
                {addUser && <AddUser close={setAddUser} user={user} /> }
            </section>
        )
    }

    const description = (
        <>
            {t("users.wantToDelete")} <span>{role}</span> ? <br />
            {t("users.loseAllAccess")}
        </>
    );

    return (
        <section className={s.users}>
            <HeaderSection
                title={t("users.usersText")}
                buttonText={t("users.addUser")}
                icon={<Plus />}
                onClick={handleAddUser}
                description={t("users.canAdd")}
            />

            <Table
                title={t("users.yourUsers")}
                placeholder={t("users.search")}
                query={query}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                handleSearch={handleSearch}
                headers={headers}
                data={users_view}
            />

            <ModalGeneral
                showModal={showModal}
                setShowModal={setShowModal}
                image={questionMark}
                buttonRight={deleteUser}
                title={t("users.deleteAUser")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                description={description}
            />
        </section>
    )
}