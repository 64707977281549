import { HeaderSection } from "components/HeaderSection/HeaderSection";
import s from "./Storage.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";
import { compressorFiles } from "utils/compressorFiles";
import {
    useDeleteFileMutation,
    useFilesUploadMutation,
    useGetFilesQuery,
    useUpdateMediaMutation
} from "../../redux/apis/mediaApi";
import { blobToBase64 } from "utils/getImages";
import {useStatusContext} from "components/StatusProvider";
import {Preloader} from "components/Preloader/Preloader";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useTranslation} from "react-i18next";
import {ListStorage} from "components/ListStorage/ListStorage";
import {sendMedia} from "../../utils/sendMedia";
import {Progress} from "../../components/Progress/Progress";

export const Storage = () => {
    const [filesUpload, {isLoading: isLoadingMedia}] = useFilesUploadMutation();
    const [deleteFile] = useDeleteFileMutation();
    const { openStatus } = useStatusContext();
    const {data: files, isFetching, refetch} = useGetFilesQuery({});
    const { t, i18n } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deletedId, setDeletedId] = useState<number>();
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [totalChunks, setTotalChunks] = useState<any>();
    const [progress, setProgress] = useState<any>();
    const [fileNameProgress, setFileNameProgress] = useState<string>("");

    useEffect(() => {
        refetch();
    }, []);

    if (isLoadingFile) return <Progress
        count={totalChunks}
        progress={progress}
        fileNameProgress={fileNameProgress}
        storage
    />

    const handleButtonClick = () => {
        const fileInput = document.getElementById("fileUpload") as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    };

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        setIsLoadingFile(true);
        const files = event.target.files;
        if (!files || files.length === 0) return;

        console.log(files?.[0])

        const result: any = await sendMedia(
            files?.[0],
            filesUpload,
            setTotalChunks,
            setProgress,
            setFileNameProgress,
            undefined,
            undefined,
            undefined,
            true
        );
        if (result?.data?.message === "file_uploaded") {
            setIsLoadingFile(false);
            openStatus('success', "status.operationSuccessful");
            refetch()
        }
    };

    const handleDeleteFile = async (id: number) => {
        const result = await deleteFile({
            id: id
        })

        if (result?.data?.message === "File deleted successful") {
            openStatus('success', "status.operationSuccessful");
        } else {
            openStatus('error', "status.error");
        }
    }

    return (
        <div className={s.wrap_media}>
            {(isFetching || isLoadingFile) && <Preloader />}
            <HeaderSection
                title={"Storage"}
                buttonText={"Add file"}
                onClick={handleButtonClick}
            />

            <input
                id="fileUpload"
                type="file"
                accept="image/*, application/pdf, video/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
            />

            <ListStorage
                files={files}
                setDeletedId={setDeletedId}
                setShowDeleteModal={setShowDeleteModal}
            />

            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={`${t("general.areYouSure")}? ${t("general.itsContents")}`}
                deletedId={deletedId}
                buttonRight={handleDeleteFile}
            />
        </div>
    );
};
