import {configureStore, Middleware} from "@reduxjs/toolkit";
import { blocksApi } from "./apis/blocksApi";
import { langApi } from "./apis/langApi";
import { mediaApi } from "./apis/mediaApi";
import { pagesApi } from "./apis/pagesApi";
import { postsApi } from "./apis/postsApi";
import { sectionApi } from "./apis/sectionApi";
import { termsApi } from "./apis/termsApi";
import { userApi } from "./apis/userApi";
import mainSlice from "./slices/mainSlice";
import { modulesApi } from "./apis/modulesApi";
import { shopApi } from "./apis/shopApi";
import {blogApi} from "./apis/blogApi";
import {documentsApi} from "./apis/documentsApi";

const interceptorMiddleware: Middleware = store => next => (action: any) => {
    const result: any = next(action);

    if (result?.payload?.status === 401) {
        console.error('Unauthorized! Redirecting to login...');
        window.location.replace('/auth');
    }

    return result;
};


export const store = configureStore({
    reducer : {
        main: mainSlice,
        [userApi.reducerPath]: userApi.reducer,
        [termsApi.reducerPath]: termsApi.reducer,
        [langApi.reducerPath]: langApi.reducer,
        [sectionApi.reducerPath]: sectionApi.reducer,
        [blocksApi.reducerPath]: blocksApi.reducer,
        [mediaApi.reducerPath]: mediaApi.reducer,
        [pagesApi.reducerPath]: pagesApi.reducer,
        [postsApi.reducerPath]: postsApi.reducer,
        [modulesApi.reducerPath]: modulesApi.reducer,
        [shopApi.reducerPath]: shopApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer
    },
    middleware : 
        (getDefaultMiddleware) => getDefaultMiddleware().concat(
            interceptorMiddleware,
            userApi.middleware, 
            termsApi.middleware,
            langApi.middleware,
            sectionApi.middleware,
            blocksApi.middleware,
            mediaApi.middleware,
            pagesApi.middleware,
            postsApi.middleware,
            modulesApi.middleware,
            shopApi.middleware,
            blogApi.middleware,
            documentsApi.middleware
       ),
});
