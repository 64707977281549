import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if(token) headers.set("token", token);
            return headers;
        }
    }),
  endpoints: (builder) => ({
      getUsers: builder.query({
        query: () => `/api/customer/get`,
        providesTags: ["Users"]
      }),

      auth: builder.mutation({
        query: (data) => ({
          url: '/api/customer/auth',
          method: 'POST',
          body: { email: data.email, password: data.password},
        }),
        invalidatesTags: ["Users"]
      }),

      addUser: builder.mutation({
        query: (data) => ({
          url: '/api/customer/add',
          method: 'POST',
          body: { email: data.email, name: data.name, password: data.password, permissions: data.permissions, role: data.role},
        }),
        invalidatesTags: ["Users"]
      }),

      deleteUser: builder.mutation({
        query: (data) => ({
          url: '/api/customer/delete',
          method: 'DELETE',
          body: {user_id: data},
        }),
        invalidatesTags: ["Users"]
      }),

      updateUser: builder.mutation({
        query: (data) => ({
          url: '/api/customer/update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ["Users"]
      }),

      getPermissions: builder.query({
        query: () => `/api/customer/permissions/get`,
        providesTags: ["Users"]
      }),

    }),
  })

export const {
  useAuthMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetPermissionsQuery
} = userApi;