import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import s from './EditModule.module.scss';
import {Back} from "components/Back/Back";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {DropDown} from "components/DropDown/DropDown";
import {useSelector} from "react-redux";
import {Input} from "components/Input/Input";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useUpdateBlockMutation} from "../../../../../../redux/apis/blocksApi";
import {useStatusContext} from "components/StatusProvider";
import {goToModule} from "utils/goToModule";
import {Preloader} from "components/Preloader/Preloader";
import {extractIds, findById} from "utils/moduleAdd";
import {sendMedia} from "utils/sendMedia";
import {useUpdateMediaMutation} from "../../../../../../redux/apis/mediaApi";
import {Progress} from "components/Progress/Progress";

type EditModuleProps = {
    element: any;
    setEditModule: React.Dispatch<any>;
    refetchGetSection: any;
}

export const EditModule = ({ element, setEditModule, refetchGetSection }: EditModuleProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const [updateBlock, {isLoading: isLoadingUpdateBlock}] = useUpdateBlockMutation();
    const { openStatus } = useStatusContext();
    const [isAddingImage, setIsAddingImage] = useState<boolean>(false);
    const [updateMedia] = useUpdateMediaMutation();
    const [totalChunks, setTotalChunks] = useState<any>();
    const [progress, setProgress] = useState<any>();
    const [langProgress, setLangProgress] = useState<string>("");
    const [fileNameProgress, setFileNameProgress] = useState<string>("");

    if (langs?.isFetching || isLoadingUpdateBlock) return <Preloader />

    const initialValues: any = {
        link: element?.link
    };

    element?.values?.forEach((value: any) => {
        initialValues[`${element?.type}-${value?.lang_key}`] = value?.value
    })

    const validation_data: any = {}

    async function uploadAllFiles(files: any[], blockId: string, updateMedia: any) {
        console.log(files);

        try {
            setIsAddingImage(true);
            let allSuccessful = true;

            for (const file of files) {
                const result: any = await sendMedia(
                    file.file,
                    updateMedia,
                    setTotalChunks,
                    setProgress,
                    setFileNameProgress,
                    file.lang_key,
                    blockId,
                    setLangProgress
                );

                console.log(result);

                if (result?.data?.message !== "file_uploaded") {
                    allSuccessful = false;
                }
            }
            if (allSuccessful) {
                openStatus('success', "status.operationSuccessful");
                refetchGetSection();
                setEditModule({ show: false });
                goToModule(element?.id);
            } else {
                openStatus('error', "status.error");
                console.log("❌ Деякі файли не завантажилися.");
                setIsAddingImage(false);
            }
        } catch (error) {
            openStatus('error', "status.error");
            console.error("❌ Помилка при завантаженні файлів:", error);
            setIsAddingImage(false);
        }
    }

    const handleSubmit = async (values: any) => {
        let changedValues: any[] = [];

        let videoValues: any[] = [];

        langs?.map((lang: any) => {
            for (let val in values) {
                if (val.includes(lang?.key)) {
                    const initialValue = initialValues[val];
                    const newValue = values[val];

                    if (initialValue !== newValue) {
                        if (element?.type === "image") {
                            changedValues.push({
                                file: newValue,
                                lang_key: lang?.key
                            });
                        } else if (element?.type === "video") {
                            changedValues.push({
                                file: "",
                                lang_key: lang?.key
                            });
                            videoValues.push({
                                file: newValue,
                                lang_key: lang.key,
                            });
                        } else {
                            changedValues.push({
                                value: newValue,
                                lang_key: lang?.key
                            });
                        }
                    }
                }
            }
        });

        const result: any = {
            block_id: element?.id,
            type: element?.type,
            link: values?.link
        };

        if (element?.type === "image" || element?.type === "video") {
            result["files"] = changedValues
        } else {
            result["terms"] = changedValues
        }

        const response = await updateBlock(result).unwrap();

        if (response?.message === "block_successfully_updated") {
            if (videoValues?.length !== 0) {
                await uploadAllFiles(
                    videoValues,
                    element?.id,
                    updateMedia,
                );
            } else {
                openStatus('success', "status.operationSuccessful");
                refetchGetSection();
                setEditModule({ show: false });
                goToModule(element?.id);
            }
        } else {
            openStatus('error', "status.error");
        }
    };

    const validationSchema = yup.object().shape(validation_data);

    return (
        <div className={s.container}>
            <Back close={setEditModule} text={t("blocksModal.backToBlocks")} />

            <div className={s.form}>
                <h2>{t("moduleModal.modifyModule")}</h2>
                {isAddingImage &&
                    <Progress
                        count={totalChunks}
                        progress={progress}
                        langProgress={langProgress}
                        fileNameProgress={fileNameProgress}
                    />
                }

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.field_container}>
                            <DropDown
                                langs={langs}
                                errors={errors}
                                touched={touched}
                                kind={element?.type}
                                label={element?.type}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            {element?.link && <Input
                                value={values.link}
                                label={t("pages.link")}
                                errors={errors.link}
                                touched={touched.link}
                                name={"link"}
                                onClick={() => setFieldValue('link', '')}
                            />}

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={t("general.confirm")}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    white
                                    onClick={() => setEditModule({show: false})}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}