import s from "./ModalStorage.module.scss";
import {useGetFilesQuery} from "../../redux/apis/mediaApi";
import {ListStorage} from "../ListStorage/ListStorage";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import {Preloader} from "../Preloader/Preloader";
import {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router-dom";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

type ModalStorageProps = {
    setIsOpenStorage: Dispatch<SetStateAction<boolean>>
}

export const ModalStorage = ({setIsOpenStorage}: ModalStorageProps) => {
    const {data: files, isFetching} = useGetFilesQuery({});
    const navigate = useNavigate();

    const handleOutsideClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            setIsOpenStorage(false);
        }
    };

    const goToStorage = () => {
        setIsOpenStorage(false);
        navigate("/storage");
    }

    return (
        <div className={s.wrapper} onClick={handleOutsideClick}>
            <div className={s.modal}>
                {isFetching && <Preloader/>}
                <div className={s.modal_top}>
                    <h1>Storage</h1>
                    <CloseIcon className={s.close} onClick={() => setIsOpenStorage(false)}/>
                </div>

                <ListStorage files={files}/>

                <PrimaryButton
                    type={"button"}
                    text={"Go to storage"}
                    onClick={goToStorage}
                    className={s.modal_bottom}
                />
            </div>
        </div>
    )
}