import s from "./Documents.module.scss";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import React, {useEffect, useState} from "react";
import {useDeleteDocumentMutation, useGetDocumentsQuery} from "../../redux/apis/documentsApi";
import {Preloader} from "components/Preloader/Preloader";
import {AddDocument} from "./AddDocument/AddDocument";
import {Term} from "utils/term";
import {handleCopyText} from "utils/copyText";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_icon2.svg";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import questionMark from "assets/images/questionMark.webp";
import {useTranslation} from "react-i18next";
import {useStatusContext} from "components/StatusProvider";
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

export const Documents = () => {
    const {data: documents, isFetching: isFetchingDocuments, refetch} = useGetDocumentsQuery({});
    const [isAddDocument, setIsAddDocument] = useState<any>({show: false, document: false});
    const [isCopied, setIsCopied] = useState<any>({done: false, id: null});
    const [deletedId, setDeletedId] = useState<number>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [deleteDocument] = useDeleteDocumentMutation();
    const { openStatus } = useStatusContext();
    const url = process.env.REACT_APP_FRONT_URL;

    const handleDeleteDocument = async (id: number) => {
        const result = await deleteDocument({
            id: id
        })

        if (result?.data?.message === "Document deleted successful") {
            openStatus('success', "status.operationSuccessful");
        } else {
            openStatus('error', "status.error");
        }
    }

    useEffect(() => {
        refetch()
    }, []);

    if (isAddDocument?.show) {
        return (
            <AddDocument setIsAddDocument={setIsAddDocument} document={isAddDocument?.document} />
        )
    }

    return (
        <div className={s.wrap_documents}>
            {isFetchingDocuments && <Preloader />}

            <HeaderSection
                title={"Documents"}
                buttonText={"Add documents"}
                onClick={() => setIsAddDocument({show: true})}
            />

            <div className={s.list}>
                {documents?.map((document: any) => (
                    <div className={s.card}>
                        <div className={s.card_top}>
                            {
                                (isCopied?.done && isCopied?.id === document?.id) ? (
                                    <>
                                        <span>Success!</span>
                                        <CheckIcon className={s.check}/>
                                    </>

                                ) : (
                                    <>
                                        <p onClick={() => handleCopyText(document, setIsCopied)}>Copy link</p>
                                        <CopyIcon className={s.copy} onClick={() => handleCopyText(document, setIsCopied)}/>
                                    </>
                                )
                            }

                            {
                                <DeleteIcon className={s.delete} onClick={() => {
                                    setDeletedId(document?.id);
                                    setShowDeleteModal(true);
                                }}
                                />
                            }
                        </div>

                        <div className={s.card_main}>
                            <h3><Term>{document?.title?.document_title}</Term></h3>

                            <p><Term>{document?.body?.document_body}</Term></p>

                            <EditIcon className={s.edit} onClick={() => setIsAddDocument({
                                show: true,
                                document: document
                            })}/>
                        </div>


                    </div>
                ))}

            </div>

            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                image={questionMark}
                description={`${t("general.areYouSure")}? ${t("general.itsContents")}`}
                deletedId={deletedId}
                buttonRight={handleDeleteDocument}
            />
        </div>
    )
}