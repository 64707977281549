import {handleCopyText} from "utils/copyText";
import React, {useEffect, useState} from "react";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete_icon2.svg";
import { ReactComponent as PdfIcon } from "assets/icons/pdf.svg";

import s from "./ListStorage.module.scss";

type ListStorageProps = {
    files: any;
    setDeletedId?: React.Dispatch<React.SetStateAction<number | undefined>>;
    setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const filtersButton = ["All", "Images", "Video", "Documents"];

export const ListStorage = ({files, setDeletedId, setShowDeleteModal}: ListStorageProps) => {
    const [isCopied, setIsCopied] = useState<any>({done: false, id: null});
    const [activeFilter, setActiveFilter] = useState<number>(0);
    const [filterFiles, setFilterFiles] = useState<any[]>(files);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleFilter(activeFilter);
    }, [files]);

    const fileTypes: any = {
        0: "all",
        1: ["jpeg", "jpg", "png", "webp", "svg"],
        2: ["mp4", "mov", "avi", "webm"],
        3: ["pdf"]
    };

    const handleFilter = (id: number) => {
        setActiveFilter(id);

        const fileType = fileTypes[id];

        if (!fileType) return;

        if (fileType === "all") {
            setFilterFiles(files);
        } else {
            setFilterFiles(files.filter((file: any) => fileType.includes(file?.mimetype)));
        }
    };

    return (
        <>
            <div className={s.filter_buttons}>
                {filtersButton?.map((button, index) => (
                    <div className={`${s.button} ${activeFilter === index ? s.button_active: ""}`} onClick={() => handleFilter(index)}>
                        {button}
                    </div>
                ))}
            </div>

            <div className={s.list}>
                {filterFiles?.length === 0 && <div>No elements</div>}
                {filterFiles?.map((file: any) => (
                    <div className={s.card}>
                        <div className={s.card_top}>
                            {
                                (isCopied?.done && isCopied?.id === file?.id) ? (
                                    <>
                                        <span>Success!</span>
                                        <CheckIcon className={s.check}/>
                                    </>

                                ) : (
                                    <>
                                        <p onClick={() => handleCopyText(file, setIsCopied)}>Copy link</p>
                                        <CopyIcon className={s.copy} onClick={() => handleCopyText(file, setIsCopied)}/>
                                    </>
                                )
                            }

                            {(setDeletedId && setShowDeleteModal) &&
                                <DeleteIcon className={s.delete} onClick={() => {
                                    setDeletedId(file?.id);
                                    setShowDeleteModal(true);
                                }}/>
                            }
                        </div>


                        <div className={s.card_image}>
                            {file?.mimetype === "pdf" ?
                                <div className={s.file}>
                                    <PdfIcon className={s.file_icon}/>
                                    <p>{file?.name}</p>
                                </div>
                                : ["jpeg", "jpg", "png", "webp", "svg"].includes(file?.mimetype) ?
                                    <img
                                        className={s.image}
                                        src={file?.path}
                                        alt="Compressed Preview"
                                    /> : ["mp4", "mov", "avi", "webm"].includes(file?.mimetype) &&
                                    <video
                                        playsInline
                                        src={file?.path}
                                        controls={true}
                                        className={s.image}
                                    />
                            }
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}