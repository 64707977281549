import React from "react";

export async function sendMedia(
    file: any,
    updateMedia: any,
    setTotalChunks: any,
    setProgress: any,
    setFileNameProgress: React.Dispatch<React.SetStateAction<string>>,
    lang?: string,
    block_id?: string,
    setLangProgress?: React.Dispatch<React.SetStateAction<string>>,
    storage?: boolean
){

    const CHUNK_SIZE = storage ? (1 * 1024 * 1024) : (5 * 1024 * 1024);

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("type", "video");
        if (block_id) {
            formData.append("block_id", block_id);
        }
        if (lang) {
            formData.append("lang_key", lang);
        }
        formData.append("file", chunk);
        formData.append("fileName", file.name);
        formData.append("chunkIndex", String(i));
        formData.append("totalChunks", String(totalChunks));
        if (storage) {
            formData.append("mimetype", file.type.split("/")[1]);
        }

        setTotalChunks(totalChunks);

        try {
            const response = await updateMedia(formData);

            setProgress(i);

            if (setLangProgress && lang) {
                setLangProgress(lang);
            }

            if (setFileNameProgress) {
                setFileNameProgress(file.name);
            }
            if (i === totalChunks - 1) {
                return response;
            }
        } catch (error) {
            console.error("Помилка при завантаженні чанка:", error);
            throw error;
        }
    }
}